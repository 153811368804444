import { useCallback } from 'react'

// const GeocodeContext = createContext<any>(undefined)

// export function GeocodeProvider({ children }: PropsWithChildren<{}>) {
//     const geocoderRef = useRef<any>()
//     const geocoder = useCallback(async () => {
//         if (!geocoderRef.current) {
//             const loader = new Loader({
//                 apiKey: ,
//                 version: 'weekly',
//                 libraries: ['places'],
//             })
//             const google = await loader.load()
//             geocoderRef.current = new google.maps.Geocoder()
//         }
//         return geocoderRef.current
//     }, [])
//     return <GeocodeContext.Provider value={geocoder}>{children}</GeocodeContext.Provider>
// }

// export function useGeocoder() {
//     const ctx = useContext(GeocodeContext)
//     if (!ctx) {
//         throw new Error('useGeocoder must be used within a GeocodeProvider')
//     }
//     return ctx
// }

export function useReverseGeocode() {
    return useCallback(async (lat: number, lng: number) => {
        const resp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=administrative_area_level_2|locality|route|postal_code&key=${process.env.REACT_APP_GEOLOCATION_API_KEY!}`)
        const json = await resp.json()
        return json.results
    }, [])
}

export function useGeocode() {
    return useCallback(async (address: string) => {
        const addressStr = address ? `${address}, FL` : ''
        const resp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressStr}&components=country:US|locality:Miami+Dade|locality:Broward+County&key=${process.env.REACT_APP_GEOLOCATION_API_KEY!}`)
        const json = await resp.json()
        return json.results
    }, [])
}