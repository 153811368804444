import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png'
import icon2x from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import './App.css'
import { MapContainer, Marker, TileLayer, GeoJSON, Tooltip, ZoomControl } from 'react-leaflet';
import browardCounty from './data/broward-county.json'
import miamiDade from './data/miami-dade.json'
import { Locality } from './Locality';
import { getRegulationStatus, capitalize, getLocalizationDef, getLocalizationDefByCounty, createLocalizationDef } from './utils';
import { SearchInput } from './SearchInput';
import { useCallback, useMemo, useState } from 'react';
// import { useReverseGeocode } from './useGeo';
import L, { LeafletEventHandlerFnMap } from 'leaflet';
import { LocalizationDef } from './types';
import { useReverseGeocode } from './useGeo'

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconRetinaUrl: icon2x,
  iconSize: [25, 41],
  shadowUrl: iconShadow,
  shadowSize: [41, 41],
  shadowAnchor: [13, 41],
});

L.Marker.prototype.options.icon = DefaultIcon

const counties: [type: string, features: any[]][] = [['BROWARD', browardCounty.features], ['MIAMI_DADE', miamiDade.features]]

function App() {
  const [selected, setSelected] = useState<LocalizationDef & { latLng: [number, number] } | null>(null)
  const reverseGeocode = useReverseGeocode()
  const eventHandlers: LeafletEventHandlerFnMap = useMemo(() => ({
    click: async (evt) => {
      if (evt && evt.latlng && typeof evt.latlng.lat !== 'undefined' && typeof evt.latlng.lng !== 'undefined') {
        const results = await reverseGeocode(evt.latlng.lat, evt.latlng.lng)
        const countyDef = results.find((r: any) => r.types.includes('administrative_area_level_2'))
        const localityDef = results.find((r: any) => r.types.includes('locality'))
        const routeDef = results.find((r: any) => r.types.includes('route'))
        const streetNumberDef = results.find((r: any) => r.types.includes('street_number')) 

        const countyName = countyDef.address_components.find((r: any) => r.types.includes('administrative_area_level_2'))?.long_name
        const localityName = localityDef.address_components.find((r: any) => r.types.includes('locality'))?.long_name
        const routeName = routeDef.address_components.find((r: any) => r.types.includes('route'))?.long_name
        const streetNumber = streetNumberDef?.address_components.find((r: any) => r.types.includes('street_number'))?.long_name

        const streetAddress = routeName !== 'Unnamed Road' ? (streetNumber ? `${streetNumber} ${routeName}` : routeName) : undefined

        if (localityName && countyName) {
          const def = createLocalizationDef(localityName, countyName, streetAddress) || getLocalizationDef(localityName) || getLocalizationDefByCounty(countyName)
          const { lat, lng } = routeDef?.geometry.location ?? localityDef?.geometry.location ?? countyDef?.geometry.location
          if (def && typeof lat !== 'undefined' && typeof lng !== 'undefined') {
            setSelected({
              ...def,
              latLng: [evt.latlng.lat, evt.latlng.lng],
            })
          }
        }
      }
    }
  }), [reverseGeocode])
  const handleSearchResultClick = useCallback((locality: LocalizationDef & { latLng: [number, number] }) => {
    setSelected(locality)
  }, [])
  const handleSearchReset = useCallback(() => {
    setSelected(null)
  }, [])
  return (
    <>
      <SearchInput onClick={handleSearchResultClick} onReset={handleSearchReset} />
      <MapContainer
        id='map'
        center={[25.771163582, -80.189499242]}
        zoom={9}
        scrollWheelZoom
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position='bottomright' />

        {counties.flatMap(([type, features], index) => features.map((feature, index) => {
          const cityName = feature.properties.CITYNAME || feature.properties.NAME
          const def = getLocalizationDef(cityName)
          const regulationStatus = getRegulationStatus(def?.regulation?.toLowerCase())

          return (
            <GeoJSON
              key={`${type}-${index}`}
              data={feature as any}
              style={{
                color: regulationStatus === 'NOT_PERMITTED' ? 'black' : type === 'BROWARD' ? '#0080ca' : '#4a36fc',
                weight: 1,
                opacity: regulationStatus === 'NOT_PERMITTED' ? 1 : 0.65
              }}
              eventHandlers={eventHandlers}
            >
              {def && <Locality def={def} />}
              <Tooltip sticky>{def?.city ?? capitalize(cityName)}</Tooltip>
            </GeoJSON>
          )
        }))}
        
        {selected && (
          <>
            <Marker position={selected.latLng}
            interactive>
              <Locality flyTo latLng={selected.latLng} def={selected} />
            </Marker>
            <Locality latLng={selected.latLng} def={selected} />
          </>
        )}
      </MapContainer>
      <img src="/stratton-logo.png" alt="stratton" className='stratton-logo' />
    </>
  );
}

export default App;
