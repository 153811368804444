import { useEffect } from 'react';
import { Popup, useMap } from 'react-leaflet';
import { LocalizationDef } from './types';
import { getSTRRegulation } from './utils';

type LocalityProps = {
    def: LocalizationDef
    flyTo?: boolean
    latLng?: [number, number]
}

export function Locality({ def, latLng, flyTo }: LocalityProps) {
    const [strRegulation, regulationStatus] = getSTRRegulation(def)
    const { notes, url, county, city } = def ?? {}
    const regulationStatusClassName = regulationStatus === 'PERMITTED' ? 'status-permitted' : 
      regulationStatus === 'NOT_PERMITTED' ? 'status-not-permitted' : 
        regulationStatus === 'TBD' ? 'status-tbd' : ''
    const map = useMap()

    useEffect(() => {
        if (flyTo && latLng) {
            map.flyTo(latLng, 15, { animate: true })
        }
    }, [flyTo, latLng, map])
        
    return (
        <Popup position={latLng}>
            <div className='mb-4'>
            <h1>{city}</h1>
            <small>{county}{county?.includes('County') ? null : ' County'}</small>
            </div>
            <div>
            <ul>
                {strRegulation && (
                <li>
                    <strong>STR Regulation:&nbsp;</strong>
                    {url ? (
                    <a href={url} target='_blank' rel='noopener noreferrer' className={regulationStatusClassName}>
                        {strRegulation}
                    </a>
                    ) : (
                    <span className={regulationStatusClassName}>
                        {strRegulation}
                    </span>
                    )}
                </li>
                )}
                {notes && (
                <li>
                    <p><strong>Notes:</strong><br />{notes}</p>
                </li>
                )}
            </ul>
            </div>
        </Popup>
    )
}