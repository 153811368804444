import { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react"
import debounce from 'lodash.debounce'
import { useGeocode } from "./useGeo"
import type { LocalizationDef } from './types'
import { createLocalizationDef, getLocalizationDef, getLocalizationDefByCounty } from "./utils"
import './SearchInput.css'

type SearchInputProps = {
    onClick?: (localization: LocalizationDef & { latLng: [number, number] }) => void
    onReset?: () => void
}

export function SearchInput({ onClick, onReset }: SearchInputProps) {
    const geocode = useGeocode()
    const inputContainerRef = useRef<HTMLDivElement | null>(null)
    const [showResults, setResultsVisible] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const debouncedGeocode = useMemo(() => debounce((address: string) => geocode(address).then((results: any) => {
        const l2AdminAreas: any[] = results.flatMap((r: any) => r.address_components.filter((ac: any) => ac.types.includes('administrative_area_level_2')))
        const isFLArea = l2AdminAreas.some((ac: any) => ac.long_name.includes('Miami-Dade') || ac.long_name.includes('Broward'))
        if (!isFLArea) {
            setError('Short Term Rentals regulations are only available for Miami-Dade and Broward counties at the moment.')
            setResultsVisible(true)
            return
        }
        setResults(results)
        setResultsVisible(true)
    }), 500), [geocode])
    const [results, setResults] = useState<any[]>([])
    const [value, setValue] = useState('')
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        const newValue = e.target.value
        setValue(newValue)
        setError(null)
        if (newValue.length === 0) {
            setResults([])
            setResultsVisible(false)
            onReset && onReset()
            return
        }
        debouncedGeocode(newValue)
    }, [debouncedGeocode, onReset])
    // const map = useMap()
    const handleClick = useCallback((result): MouseEventHandler<HTMLDivElement> => (e) => {
        const countyDef = result.address_components.find((r: any) => r.types.includes('administrative_area_level_2'))
        const localityDef = result.address_components.find((r: any) => r.types.includes('locality'))
        const streetName = result.address_components.find((r: any) => r.types.includes('route'))
        const streetNumber = result.address_components.find((r: any) => r.types.includes('street_number')) 
        const streetAddress = streetNumber?.long_name ? `${streetNumber?.long_name} ${streetName?.long_name}` : streetName?.long_name
        const def = getLocalizationDef(localityDef.long_name) || getLocalizationDefByCounty(countyDef.long_name) || createLocalizationDef(localityDef.long_name, countyDef.long_name, streetAddress)
        const { lat, lng } = result.geometry.location
        // drop pin here, with popup.
        onClick && onClick({ ...def, latLng: [lat, lng] })
        setResultsVisible(false)
    }, [onClick])

    const handleFocus = useCallback(() => {
        if (value && results.length > 0) {
            setResultsVisible(true)
        } else if (value && results.length === 0) {
            debouncedGeocode(value)
        }
    }, [value, debouncedGeocode, results])


    useEffect(() => {
        const inputContainer = inputContainerRef.current
        const handleClickOutside = (evt: any) => {
            if (inputContainer && !inputContainer.contains(evt.target) && showResults) {
                setResultsVisible(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [showResults])
    return (
        <div className='search-container' ref={el => {
            inputContainerRef.current = el
        }}>
            <input
                className='search-input'
                type='search'
                placeholder='Enter an address...'
                value={value}
                onFocus={handleFocus}
                onChange={handleChange}
            />
            {showResults && (
                <div className="results-container">
                    {error ? (
                        <div
                            style={{
                                padding: '0.5rem 1rem'
                            }}
                        >
                            <strong style={{
                                color: 'rgb(249, 88, 70)'
                            }}>{error}</strong>
                        </div>
                    ) : results.length > 0 ? results.map((result: any) => (
                            <div
                                key={result.place_id}
                                style={{
                                    padding: '0.5rem 1rem',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClick(result)}
                            >
                                <strong>{result.formatted_address}</strong>
                            </div>
                        )
                    ) : (
                        <div
                            style={{
                                padding: '0.5rem 1rem'
                            }}
                        >
                            <strong>{'No results found'}</strong>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}