import { LocalizationDef, RegulationStatus } from "./types"
import localization from './data/localization.json'

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}


export const getLocalizationDef = (municipality: string): LocalizationDef | undefined => {
    const def = localization['STR Map'].find(el => el.City.toLowerCase().replaceAll('-', ' ') === municipality.toLowerCase().replaceAll('-', ' '))
    if (def) {
      return {
        city: def.City,
        county: def.County,
        status: def.Status,
        zipCode: def['Zip Code'],
        notes: def.Notes,
        url: def.URL,
        regulation: def['STR Regulation'],
        _type: 'MUNICIPALITY_DEF',
      }
    }
    return undefined
}

export const getLocalizationDefByCounty = (county: string): LocalizationDef | undefined => {
    const def = localization['STR Map'].find(el => el.County.toLowerCase().replaceAll('-', ' ') === county.toLowerCase().replaceAll('-', ' '))
    if (def) {
      return {
        city: def.City,
        county: def.County,
        status: def.Status,
        zipCode: def['Zip Code'],
        notes: def.Notes,
        url: def.URL,
        regulation: def['STR Regulation'],
        _type: 'COUNTY_DEF',
      }
    }
    return undefined
}

export const getRegulationStatus = (text?: string) => {
    if (!text) return undefined

    if (text.includes('not permitted')) {
        return 'NOT_PERMITTED'
    }
    if (text.includes('permitted')) {
        return 'PERMITTED'
    }
    if (text.includes('tbd')) {
        return 'TBD'
    }
    return undefined
}

export const getSTRRegulation = (localDef?: LocalizationDef): [text?: string, status?: RegulationStatus] => {
    if (localDef?.regulation) {
        const status = getRegulationStatus(localDef.regulation.toLowerCase())
        return [localDef.regulation, status]
    }
    return [undefined, undefined]
}

export const createLocalizationDef = (localityName: string, countyName: string, streetName?: string): LocalizationDef => {
    const def = localization['STR Map'].find(el => {
        return el.City.toLowerCase().replaceAll('-', ' ') === localityName.toLowerCase().replaceAll('-', ' ')
    }) ?? localization['STR Map'].find(el => el.County.toLowerCase().replaceAll('-', ' ') === countyName.toLowerCase().replaceAll('-', ' '))
    if (def) {
        const locality: LocalizationDef = {
            ...def,
            county: def.County,
            notes: def.Notes,
            regulation: def["STR Regulation"],
            status: def.Status,
            url: def.URL,
            zipCode: def["Zip Code"],
            city: streetName ?? localityName,
            _type: 'MUNICIPALITY_DEF',
        }
        return locality
    }
    return {
        city: streetName ?? localityName,
        county: countyName,
        status: 'tbd',
        regulation: 'unknown',
        _type: 'MUNICIPALITY_DEF',
    }
}